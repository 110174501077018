import loading from "./loading.gif";

function Loading() {
    return (
        <>
            <center>
                <img src={loading} alt="Loading.." />

            </center>
        </>
    )
}

export default Loading;