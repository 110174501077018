import './App.css';
import { useEffect, useState } from "react";
import Loading from './Loading';
import axios from 'axios';
function App() {
  const [news, setNews] = useState([]);
  const [news30, setNews30] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axios.get("https://hacker-news.firebaseio.com/v0/topstories.json");
        setNews(data);
        let apicalls = data.slice(0, 30).map(id => axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`));
        let responses = await Promise.all(apicalls);
        responses = responses.map(res => res.data);
        setNews30(responses);
        setCount(count + 30);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
      // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  const moreNews = async () => {
    try {
      setLoading(true);
      let apicalls;
      if (count > 500) {
        setCount(0);
        apicalls = news.slice(0, 30).map(id => axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`));
      } else {
        apicalls = news.slice(count, count + 30).map(id => axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`));
      }
      let responses = await Promise.all(apicalls);
      responses = responses.map(res => res.data);
      setNews30(responses);
      setCount(count + 30);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="header">
       <a href="/" style={{textDecoration : "none", color : "white"}}>
       <h1>Hacker News</h1>
       </a>
      </div>
      <div className="row">
        <div className="main">
          {loading ? <Loading /> : (
            <>
              <center>
                {news30.map((elem, i) => (
                  <div key={i} className="news">
                    <a href={elem.url} target="_blank" rel="noreferrer">
                      <h3><span> {(count - 30) + (i + 1)} </span> {elem.title} </h3>
                      <h5>{elem.url}</h5>
                    </a>
                    <p><b>{elem.score}</b> points by <b>{elem.by}</b> {Math.floor((new Date() - new Date(elem.time * 1000)) / (1000 * 60 * 60))} Hours ago | {elem.descendants} comments</p>
                  </div>
                ))}
                <hr />
                <button onClick={moreNews}> More </button>
              </center>
            </>
          )}
        </div>
      </div>
    </>
  );
}


export default App;